import AdminAPI from '@/api/requests/admin'
import { IGetRowsParams } from 'ag-grid-community'
import DataSource from '@/api/datasource/datasource'

function createFilter(filter: { [k: string]: any }) {
  if (filter.roles) {
    filter['roles'] = {
      filterType: 'array',
      array: filter.roles.filter
    }
  }
  if (filter.organizations) {
    const filtering = filter.organizations.filter
    delete filter.organizations
    filter['organizations.title'] = {
      filterType: 'text',
      type: 'contains',
      filter: filtering
    }
  }
  if (filter.moodleRights) {
    filter['moodleRights'] = {
      filterType: filter.moodleRights.filterType,
      type: 'equals',
      filter: filter.moodleRights.type
    }
  }
  return { filter }
}

function createSort(sort: { [k: string]: any }) {
  if (sort.organizations) {
    const sorting = sort.organizations
    delete sort.organizations
    sort['organizations.title'] = sorting
  }
  return sort
}

export class AdminUsersDataSource extends DataSource {
  public isInvite = true
  getRows(params: IGetRowsParams) {
    const limit = params.endRow - params.startRow
    const offset = params.startRow
    const sort = this.castSortModel(params.sortModel)
    const newSort = createSort(sort)
    const filter = this.castFilterModel(params.filterModel)
    const newFilter = createFilter(filter)
    AdminAPI.getUsers(
      limit,
      offset,
      newSort,
      newFilter,
      !this.isInvite ? ['notActivated'] : ['onlyActivated']
    ).then(resp => {
      params.successCallback(resp.data.users, resp.data.count)
    })
  }
}
