
















































































import { Vue, Component } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import ActionRenderer from '@/components/table/ActionRenderer.vue'
import _ from 'lodash'
import { AdminUsersDataSource } from '@/api/datasource/admin/adminUsers'
import EditAddModal from '@/components/admin/modal/EditAddUserModal.vue'
import UsersTable from '@/components/admin/UsersTable.vue'
import AdminAPI from '@/api/requests/admin'
import UserFactory from '@/factories/user'
import InviteStudentsModal from '@/components/admin/modal/InviteUserModal.vue'
import ResDelModal from '@/components/admin/modal/ResDelUserModal.vue'
import { userMapper } from '@/store/modules/user'
import { toastMapper } from '@/store/modules/toast'
import ExcelAPI from '@/api/requests/excel'
import { inviteUserModal } from '@/config/types/inviteUserModal'

const Mapper = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser'])
  },
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component({
  components: {
    AgGridVue,
    ActionRenderer,
    EditAddModal,
    UsersTable,
    InviteStudentsModal,
    ResDelModal
  }
})
export default class AdminListOfUsers extends Mapper {
  private dataSource: AdminUsersDataSource = new AdminUsersDataSource()
  private typeStudentsModal = inviteUserModal.addStudents

  $refs!: {
    userAddEditModal: EditAddModal
  }
  private textOfInviteButton = 'Показать приглашенных'
  private delResetUserObject = UserFactory.emptyUser()
  private userModel = UserFactory.emptyUser()

  private tableStyle = {
    width: '100%',
    height: 'calc(100vh - 155px)'
  }

  private onAddUserClick() {
    this.$bvModal.show('addUser')
  }

  private onDeleteUserModal(params: any) {
    this.delResetUserObject = params.node.data
    this.$bvModal.show('deleteUser')
  }

  private onResetUserModal(params: any) {
    this.delResetUserObject = params.node.data
    this.$bvModal.show('resetUser')
  }

  private onProfileRedirect(params: any) {
    if (params.node.data) {
      this.$router.push(`/profile/${params.node.data.id}`)
    }
  }

  private async onDeleteCallback() {
    const deleteUser = _.cloneDeep(this.delResetUserObject)
    if (deleteUser.id && deleteUser.id !== this.currentUser.id) {
      AdminAPI.deleteUser(deleteUser.id).then(() => {
        this.reDrawTable()
      })
    } else {
      this.pushToast({
        error: true,
        title: 'Ошибка удаления',
        message: 'Вы не можете удалить текущего пользователя',
        time: 2
      })
    }
  }

  private onShowInvite() {
    this.dataSource!.isInvite = !this.dataSource!.isInvite
    this.textOfInviteButton = this.dataSource!.isInvite
      ? 'Показать приглашенных'
      : 'Показать зарегистрированных'
    this.reDrawTable()
  }

  private onStudentsInvite() {
    this.$bvModal.show('addStudents')
  }

  private onResetCallback() {
    const resetUser = this.delResetUserObject
    if (resetUser.id) {
      AdminAPI.resetUser(resetUser.email)
    }
  }

  private onEditUserModal(params: any) {
    this.userModel = _.cloneDeep(params.node.data)
    this.$refs.userAddEditModal.openEditModal(this.userModel)
    this.$bvModal.show('editUser')
  }

  private onExcel() {
    ExcelAPI.exportUsersExcel({}, null)
  }

  private onHiddenResOrDel() {
    this.delResetUserObject = UserFactory.emptyUser()
  }

  private reDrawTable() {
    const usersTableInstance = (this.$refs as any).usersTable
    if (usersTableInstance) {
      usersTableInstance.reDrawTable()
    }
  }
}
