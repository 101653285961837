import { render, staticRenderFns } from "./AdminListOfUsers.vue?vue&type=template&id=392e6b8e&scoped=true&"
import script from "./AdminListOfUsers.vue?vue&type=script&lang=ts&"
export * from "./AdminListOfUsers.vue?vue&type=script&lang=ts&"
import style0 from "./AdminListOfUsers.vue?vue&type=style&index=0&id=392e6b8e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "392e6b8e",
  null
  
)

export default component.exports